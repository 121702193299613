import {AfterViewInit, Component, inject} from '@angular/core';
import {
  DeleteStatus,
  ItemMode,
  MasterDataStatus,
  patchFormValue,
  REGEX
} from '../../../shared/shared.service';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProxyDynamicService} from '../../../shared/service/proxy-dynamic.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-service-package-item',
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class ItemComponent implements AfterViewInit {
  readonly nzModalData = inject(NZ_MODAL_DATA);
  formConfig = [];
  itemMode = ItemMode;
  formGroup!: FormGroup;
  mode = ItemMode.Add;
  listApartmentType: any[] = [];
  listDataService: any[] = [{service_id: null, revenue_split_rate: null}];
  listDataServiceDeleted: any[] = [];
  listStatus: any[] = [];
  listOperate: any[] = [];
  listService: any[] = [];
  listCost: any[] = [{apartment_type: null, price_by_owner: null, price_by_guest: null}];
  listCostDeleted: any[] = [];
  regex = REGEX;
  expandCost = true;
  expandPack = true;
  deleteStatus = DeleteStatus;

  get f() {
    return this.formGroup.controls;
  }

  constructor(
    private modal: NzModalRef,
    private fb: FormBuilder,
    private service: ProxyDynamicService,
    private noti: NzNotificationService,
    private translateService: TranslateService,
  ) {
    this.formConfig = this.nzModalData.formConfig;
    this.listApartmentType = this.nzModalData.listApartmentType;
    this.listService = this.nzModalData.listService;
    this.listOperate = this.nzModalData.listOperate;
    this.listStatus = this.nzModalData.listStatus;
    this.formGroup = this.fb.group({
      id: [undefined],
      code: [null, [Validators.required, Validators.pattern(this.regex.code)]],
      name: [null, Validators.required],
      note: [null],
      operation_type: [null],
      free_nights_owner: [null],
      discount_for_owner: [null, [Validators.min(0), Validators.max(100)]],
      status: [MasterDataStatus.Active, Validators.required],
      service_apply: [[]],
      package_price: [[]],
      creator: [undefined],
      date_created: [undefined],
      updater: [undefined],
      date_updated: [undefined],
    });
  }

  ngAfterViewInit() {
    this.mode = this.nzModalData.mode;
    if (this.nzModalData.mode === ItemMode.Add) {
      this.f['status'].disable();
    } else {
      this.listDataService = JSON.parse(JSON.stringify(this.nzModalData.data.service_apply.filter((r: any) => r.deleted === DeleteStatus.NO).map((item: any) => {
        item['service_id'] = item.service;
        return item;
      })));
      this.listCost = JSON.parse(JSON.stringify(this.nzModalData.data.package_price.filter((r: any) => r.deleted === DeleteStatus.NO)));
      if (this.nzModalData.mode === ItemMode.View) {
        patchFormValue(this.formGroup, this.nzModalData.data);
        this.f['date_created'].setValue(this.nzModalData.data.date_created ? moment(this.nzModalData.data.date_created, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
        this.f['date_updated'].setValue(this.nzModalData.data.date_updated ? moment(this.nzModalData.data.date_updated, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
        this.formGroup.disable({emitEvent: false});
      } else {
        if (this.nzModalData.mode === ItemMode.Edit) {
          patchFormValue(this.formGroup, this.nzModalData.data);
          this.f['date_created'].setValue(this.nzModalData.data.date_created ? moment(this.nzModalData.data.date_created, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
          this.f['date_updated'].setValue(this.nzModalData.data.date_updated ? moment(this.nzModalData.data.date_updated, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
          this.formGroup.enable({emitEvent: false});
        }
      }
    }
  }

  edit() {
    this.mode = ItemMode.Edit;
    patchFormValue(this.formGroup, this.nzModalData.data);
    this.f['date_created'].setValue(this.nzModalData.data.date_created ? moment(this.nzModalData.data.date_created, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
    this.f['date_updated'].setValue(this.nzModalData.data.date_updated ? moment(this.nzModalData.data.date_updated, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null)
    this.formGroup.enable({emitEvent: false});
  }

  destroyModal() {
    this.modal.destroy()
  }

  onSave() {
    const valid = this.validateTable();
    if (this.formGroup.valid && valid) {
      let listApplyService: any[] = [];
      let listPackagePrice: any[] = [];
      if (this.listCostDeleted.length) {
        listPackagePrice = this.listCostDeleted;
      }
      if (this.listDataServiceDeleted.length) {
        listApplyService = this.listDataServiceDeleted;
      }
      this.f['service_apply'].setValue(listApplyService.concat(this.listDataService));
      this.f['package_price'].setValue(listPackagePrice.concat(this.listCost));
      const body = this.formGroup.getRawValue();
      body.creator = undefined;
      body.updater = undefined;
      body.date_created = undefined;
      body.date_updated = undefined;
      if (this.mode === ItemMode.Add) {
        body.id = undefined;
        this.service.createItem('package/create', body).subscribe((res) => {
          this.modal.destroy(true)
        }, (error: any) => {
         this.service.handleError(error);
        });
      }
      if (this.mode === ItemMode.Edit) {
        this.service.updateItem(this.nzModalData.collection, body.id, body).subscribe(res => {
          this.modal.destroy(true)
        }, (error: any) => {
         this.service.handleError(error);
        });
      }
    } else {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  addCost() {
    this.listCost.push({apartment_type: null, price_by_owner: null, price_by_guest: null});
  }

  deleteCost(index: number) {
    if (this.listCost[index].id) {
      this.listCost[index]['deleted'] = DeleteStatus.YES;
      this.listCostDeleted.push(this.listCost[index])
    }
    setTimeout(() => {
      this.listCost.splice(index, 1);
      if (!this.listCost.length) {
        this.addCost();
      }
    }, 200);
  }

  addDataService() {
    this.listDataService.push({service_id: null, revenue_split_rate: null});
  }

  deleteDataService(index: number) {
    if (this.listDataService[index].id) {
      this.listDataService[index]['deleted'] = DeleteStatus.YES;
      this.listDataServiceDeleted.push(this.listDataService[index])
    }
    setTimeout(() => {
      this.listDataService.splice(index, 1);
      if (!this.listDataService.length) {
        this.addDataService();
      }
    }, 200);
  }

  validateExistPackage() {
    let check = true;
    for (let i = 0; i < this.listCost.length; i++) {
      if (this.listCost.findIndex((r: any, index: number) => r.apartment_type === this.listCost[i].apartment_type && index !== i) !== -1) {
        check = false;
        break;
      }
    }
    return check;
  }

  validateExistService() {
    let check = true;
    for (let i = 0; i < this.listDataService.length; i++) {
      if (this.listDataService.findIndex((r: any, index: number) => r.service_id === this.listDataService[i].service_id && index !== i) !== -1) {
        check = false;
        break;
      }
    }
    return check;
  }

  validatePackage() {
    return this.listCost.filter((r: any) => !(r.apartment_type && r.price_by_owner !== null && r.price_by_guest !== null)).length === 0;
  }

  validateService() {
    return this.listDataService.filter((r: any) => !(r.service_id && r.revenue_split_rate !== null)).length === 0;
  }

  validateTable() {
    if (!this.validatePackage()) {
      this.noti.create('error', '', this.translateService.instant('service-package.error-required-cost'))
      return false;
    } else if (!this.validateExistPackage()) {
      this.noti.create('error', '', this.translateService.instant('service-package.error-exist-cost'))
      return false;
    } else if (!this.validateService()) {
      this.noti.create('error', '', this.translateService.instant('service-package.error-required-rate'))
      return false;
    } else if (!this.validateExistService()) {
      this.noti.create('error', '', this.translateService.instant('service-package.error-exist-rate'))
      return false;
    }
    return true;
  }
}
