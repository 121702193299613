<form [formGroup]="formGroup">
  <div class="bg-white p-3" style="border-radius: 4px">
    <h4>{{ 'service-package.package-info' | translate }}</h4>
    <div nz-row [nzGutter]="[16, 8]">
      <div nz-col nzSpan="12">
        <app-control formControlName="code" [controlConfig]="{title: 'service-package.code', type: 'text', max: 20, required: true, regex: regex.code, hint: true, hintLabel: 'input-hint.code'}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="name" [controlConfig]="{title: 'service-package.name', type: 'text', max: 250, required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="operation_type" [options]="listOperate" [controlConfig]="{title: 'operate.title', type: 'dropdown'}"></app-control>
      </div>
      <div nz-col nzSpan="6">
        <app-control formControlName="free_nights_owner" [controlConfig]="{title: 'service-package.free-night', type: 'number'}"></app-control>
      </div>
      <div nz-col nzSpan="6">
        <app-control formControlName="discount_for_owner" [controlConfig]="{title: 'service-package.owner-discount', max: 100, min: 0, maxLength: 3, type: 'number'}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="status" [controlConfig]="{title: 'status.title', type: 'status', required: true, options: listStatus}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="note" [controlConfig]="{title: 'description', type: 'textarea'}"></app-control>
      </div>
    </div>
  </div>
  <div class="bg-white p-3 mt-2" style="border-radius: 4px">
    <div class="w-100 d-flex justify-content-between">
      <h4 class="mb-0">{{ 'service-package.cost-info' | translate }}</h4>
      <div>
        <lucide-icon [name]="expandCost ? 'chevron-up' : 'chevron-down'" (click)="expandCost = !expandCost"></lucide-icon>
      </div>
    </div>
    <div *ngIf="expandCost">
      <nz-table #table [nzData]="listCost" [nzScroll]="listCost.length > 5 ? {y: '10vh'} : {}" nzTableLayout="auto" [nzBordered]="true" nzSize="small"
                [nzShowPagination]="false" class="mt-3">
        <thead>
        <tr>
          <th style="width: 60px">{{ 'index' | translate }}</th>
          <th>{{ 'apartment-type.label' | translate }}</th>
          <th>{{ 'service-package.costByOwner' | translate }}</th>
          <th>{{ 'service-package.costByGuest' | translate }}</th>
          <th style="width: 60px" *ngIf="mode !== itemMode.View"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of listCost; let i = index">
          <tr *ngIf="item['deleted'] !== deleteStatus.YES">
            <td class="text-center">{{ i + 1 }}</td>
            <td>
            <span *ngIf="mode === itemMode.View">
              <ng-container *ngFor="let itemType of listApartmentType">
                <div *ngIf="itemType.value === item['apartment_type']">{{ itemType.text | translate }}</div>
              </ng-container>
            </span>
              <app-model *ngIf="mode !== itemMode.View" [value]="item['apartment_type']" (modelValueChange)="item['apartment_type'] = $event" [controlConfig]="{label: 'apartment-type.label', type: 'dropdown', options: listApartmentType}"></app-model>
            </td>
            <td>
              <span *ngIf="mode === itemMode.View">{{ item['price_by_owner'] | number }}</span>
              <app-model *ngIf="mode !== itemMode.View" [value]="item['price_by_owner']" (modelValueChange)="item['price_by_owner'] = $event" [controlConfig]="{label: 'service-package.costByOwner', type: 'number', min: 0}"></app-model>
            </td>
            <td>
              <span *ngIf="mode === itemMode.View">{{ item['price_by_guest'] | number }}</span>
              <app-model *ngIf="mode !== itemMode.View" [value]="item['price_by_guest']" (modelValueChange)="item['price_by_guest'] = $event" [controlConfig]="{label: 'service-package.costByGuest', type: 'number', min: 0}"></app-model>
            </td>
            <td class="text-center" *ngIf="mode !== itemMode.View">
              <app-button [type]="'confirm'" [disabled]="listCost.length === 1" [confirmLabel]="'confirm-del' | translate" [label]="'trash-2'" (clickButton)="deleteCost(i)"></app-button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </nz-table>
      <app-button *ngIf="mode !== itemMode.View" class="mt-3 d-block" (clickButton)="addCost()" [type]="'primary'" [iconLeft]="'plus'" [label]="'addChild' | translate"></app-button>
    </div>
  </div>
  <div class="bg-white p-3 mt-2" style="border-radius: 4px">
    <div class="w-100 d-flex justify-content-between">
      <h4 class="mb-0">{{ 'service-package.apply-service' | translate }}</h4>
      <div>
        <lucide-icon [name]="expandPack ? 'chevron-up' : 'chevron-down'" (click)="expandPack = !expandPack"></lucide-icon>
      </div>
    </div>
    <div *ngIf="expandPack">
      <nz-table #table1 [nzData]="listDataService" [nzScroll]="listDataService.length > 5 ? {y: '10vh'} : {}" nzTableLayout="auto" [nzBordered]="true" nzSize="small"
                [nzShowPagination]="false" class="mt-3">
        <thead>
        <tr>
          <th style="width: 60px">{{ 'index' | translate }}</th>
          <th>{{ 'service.label' | translate }}</th>
          <th>{{ 'service-package.revenuePercent' | translate }}</th>
          <th style="width: 60px" *ngIf="mode !== itemMode.View"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of listDataService; let i = index">
          <tr *ngIf="item['deleted'] !== deleteStatus.YES">
            <td class="text-center">{{ i + 1 }}</td>
            <td>
            <span *ngIf="mode === itemMode.View">
              <ng-container *ngFor="let itemService of listService">
                <div *ngIf="itemService.value === item['service']">{{ itemService.text | translate }}</div>
              </ng-container>
            </span>
              <app-model *ngIf="mode !== itemMode.View" [value]="item['service_id']" (modelValueChange)="item['service_id'] = $event" [controlConfig]="{label: 'service.label', type: 'dropdown', options: listService}"></app-model>
            </td>
            <td>
              <span *ngIf="mode === itemMode.View">{{ item['revenue_split_rate'] }}</span>
              <app-model *ngIf="mode !== itemMode.View" [value]="item['revenue_split_rate']" (modelValueChange)="item['revenue_split_rate'] = $event" [controlConfig]="{label: 'service-package.revenuePercent', type: 'number', min: 0, max: 100}"></app-model>
            </td>
            <td class="text-center" *ngIf="mode !== itemMode.View">
              <app-button [disabled]="listDataService.length === 1" [type]="'confirm'" [confirmLabel]="'confirm-del' | translate" [label]="'trash-2'" (clickButton)="deleteDataService(i)"></app-button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </nz-table>
      <app-button *ngIf="mode !== itemMode.View" class="mt-3 d-block" (clickButton)="addDataService()" [type]="'primary'" [iconLeft]="'plus'" [label]="'addChild' | translate"></app-button>
    </div>
  </div>
  <div class="bg-white p-3 mt-2" style="border-radius: 4px" *ngIf="mode === itemMode.View">
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col nzSpan="6">
        <app-control formControlName="creator" [controlConfig]="{title: 'creator', type: 'text'}"></app-control>
      </div>
      <div nz-col nzSpan="6">
        <app-control formControlName="date_created" [controlConfig]="{title: 'created', type: 'date', showTime: true}"></app-control>
      </div>
      <div nz-col nzSpan="6">
        <app-control formControlName="updater" [controlConfig]="{title: 'updater', type: 'text'}"></app-control>
      </div>
      <div nz-col nzSpan="6">
        <app-control formControlName="date_updated" [controlConfig]="{title: 'updated', type: 'date', showTime: true}"></app-control>
      </div>
    </div>
  </div>
</form>
<div *nzModalTitle class="title-modal">{{ mode |translate }} {{ 'service-package.label' | translate }}</div>
<div *nzModalFooter>
  <ng-container *ngIf="mode == itemMode.View">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button nz-button nzType="primary" (click)="edit()">{{ 'edit' | translate }}</button>
  </ng-container>
  <ng-container *ngIf="mode == itemMode.Edit || mode == itemMode.Add">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button nz-button nzType="primary" (click)="onSave()">{{ 'save' | translate }}</button>
  </ng-container>
</div>
